import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/view/pages/Dashboard2.vue"),
        meta: {
          title: 'Nimbang Balita - Dashboard',
          access_right: '1001'
        }
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/view/pages/Builder.vue")
      },
      // {
      //   path: "/classroom/join",
      //   name: "join-class",
      //   component: () => import("@/view/pages/classroom/JoinClass.vue"),
      //   meta:{
      //     title: 'Nimbang Balita - Gabung Kelas'
      //   }
      // },



      {
        path: "/report",
        name: "report",
        redirect: "/report/visitors",
        component: () => import("@/view/pages/reports/Report.vue"),
        meta: {
          title: 'Nimbang Balita | Laporan Pengunjung'
        },
        children: [
          {
            path: "/report/visitors",
            name: "report-visitor",
            component: () => import("@/view/pages/reports/ReportVisitor.vue"),
            meta: {
              title: 'Nimbang Balita | Laporan Pengunjung'
            },
          },
          {
            path: "/report/visitor-all",
            name: "report-visitor-all",
            component: () => import("@/view/pages/reports/ReportVisitorAll.vue"),
            meta: {
              title: 'Nimbang Balita | Laporan Pengunjung'
            },
          },
        ]
      },
      {
        path: "/weighing-mothers",
        name: "weighing-mothers",
        redirect: "/weighing-mothers/list",
        component: () => import("@/view/pages/weighing-mothers/WeighingMother.vue"),
        meta: {
          title: 'Nimbang Balita | Penimbangan Ibu Hamil'
        },
        children: [
          {
            path: "/weighing-mothers/list",
            name: "weighing-mothers-list",
            component: () => import("@/view/pages/weighing-mothers/List.vue"),
            meta: {
              title: 'Nimbang Balita | Penimbangan Ibu Hamil'
            },
          },
          {
            path: "/weighing-mothers/add",
            name: "weighing-mothers-add",
            component: () => import("@/view/pages/weighing-mothers/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Penimbangan Ibu Hamil'
            },
          },
          {
            path: "/weighing-mothers/edit/:id",
            name: "weighing-mothers-edit",
            component: () => import("@/view/pages/weighing-mothers/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Penimbangan Ibu Hamil'
            },
          },
          {
            path: "/weighing-mothers/detail/:id",
            name: "weighing-mothers-detail",
            component: () => import("@/view/pages/weighing-mothers/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Penimbangan Ibu Hamil'
            },
          },
          // {
          //   path: "/weighing-mother/report",
          //   name: "weighing-mother-report",
          //   component: () => import("@/view/pages/weighing-mother/Report.vue"),
          //   meta: {
          //     title: 'Nimbang Balita | Penimbangan Ibu Hamil'
          //   },
          // },
        ]
      },
      {
        path: "/weighing-notes",
        name: "weighing-notes",
        redirect: "/weighing-notes/list",
        component: () => import("@/view/pages/weighing-notes/WeighingNotes.vue"),
        meta: {
          title: 'Nimbang Balita | Penimbangan Bayi & Balita'
        },
        children: [
          {
            path: "/weighing-notes/list",
            name: "weighing-notes-list",
            component: () => import("@/view/pages/weighing-notes/List.vue"),
            meta: {
              title: 'Nimbang Balita | Penimbangan Bayi & Balita'
            },
          },
          {
            path: "/weighing-notes/add",
            name: "weighing-notes-add",
            component: () => import("@/view/pages/weighing-notes/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Penimbangan Bayi & Balita'
            },
          },
          {
            path: "/weighing-notes/edit/:id",
            name: "weighing-notes-edit",
            component: () => import("@/view/pages/weighing-notes/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Penimbangan Bayi & Balita'
            },
          },
          {
            path: "/weighing-notes/detail/:id",
            name: "weighing-notes-detail",
            component: () => import("@/view/pages/weighing-notes/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Penimbangan Bayi & Balita'
            },
          },
          {
            path: "/weighing-notes/report",
            name: "weighing-notes-report",
            component: () => import("@/view/pages/weighing-notes/Report.vue"),
            meta: {
              title: 'Nimbang Balita | Laporan'
            },
          },
          {
            path: "/weighing-notes/report-all",
            name: "weighing-notes-report",
            component: () => import("@/view/pages/weighing-notes/ReportAll.vue"),
            meta: {
              title: 'Nimbang Balita | Laporan'
            },
          },
        ]
      },
      {
        path: "/mothers",
        name: "mothers",
        redirect: "/mothers/list",
        component: () => import("@/view/pages/mothers/Mothers.vue"),
        meta: {
          title: 'Nimbang Balita | Registrasi PUS-WUS'
        },
        children: [
          {
            path: "/mothers/list",
            name: "mothers-list",
            component: () => import("@/view/pages/mothers/List.vue"),
            meta: {
              title: 'Nimbang Balita | Registrasi PUS-WUS'
            },
          },
          {
            path: "/mothers/add",
            name: "mothers-add",
            component: () => import("@/view/pages/mothers/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Registrasi PUS-WUS'
            },
          },
          {
            path: "/mothers/edit/:id",
            name: "mothers-edit",
            component: () => import("@/view/pages/mothers/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Registrasi PUS-WUS'
            },
          },
          {
            path: "/mothers/detail/:id",
            name: "mothers-detail",
            component: () => import("@/view/pages/mothers/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Registrasi PUS-WUS'
            },
          },
        ]
      },
      {
        path: "/children",
        name: "children",
        redirect: "/children/list",
        component: () => import("@/view/pages/children/Children.vue"),
        meta:{
          title: 'Nimbang Balita | Anak'
        },
        children: [
          {
            path: "/children/list",
            name: "children-list",
            component: () => import("@/view/pages/children/List.vue"),
            meta:{
              title: 'Nimbang Balita | Anak'
            },
          },
          {
            path: "/children/add/:motherId",
            name: "children-add",
            component: () => import("@/view/pages/children/Add.vue"),
            meta:{
              title: 'Nimbang Balita | Anak'
            },
          },
          {
            path: "/children/edit/:id",
            name: "children-edit",
            component: () => import("@/view/pages/children/Edit.vue"),
            meta:{
              title: 'Nimbang Balita | Anak'
            },
          },
          {
            path: "/children/detail/:id",
            name: "children-detail",
            component: () => import("@/view/pages/children/Detail.vue"),
            meta:{
              title: 'Nimbang Balita | Anak'
            },
          },
        ]
      },
      {
        path: "/toddlers",
        name: "toddler",
        redirect: "/toddlers/list",
        component: () => import("@/view/pages/toddler/Toddler.vue"),
        meta: {
          title: 'Nimbang Balita | Registrasi Balita'
        },
        children: [
          {
            path: "/toddlers/list",
            name: "toddler-list",
            component: () => import("@/view/pages/toddler/List.vue"),
            meta: {
              title: 'Nimbang Balita | Registrasi Balita'
            },
          },
          {
            path: "/toddlers/add",
            name: "toddler-add",
            component: () => import("@/view/pages/toddler/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Registrasi Balita'
            },
          },
          {
            path: "/toddlers/edit/:id",
            name: "toddler-edit",
            component: () => import("@/view/pages/toddler/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Registrasi Balita'
            },
          },
          {
            path: "/toddlers/detail/:id",
            name: "toddler-detail",
            component: () => import("@/view/pages/toddler/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Registrasi Balita'
            },
          },
        ]
      },
      {
        path: "/cadre",
        name: "cadre",
        redirect: "/cadre/list",
        component: () => import("@/view/pages/cadre/Cadre.vue"),
        meta: {
          title: 'Nimbang Balita | Kader Posyandu'
        },
        children: [
          {
            path: "/cadre/list",
            name: "cadre-list",
            component: () => import("@/view/pages/cadre/List.vue"),
            meta: {
              title: 'Nimbang Balita | Kader Posyandu'
            },
          },
          {
            path: "/cadre/add",
            name: "cadre-add",
            component: () => import("@/view/pages/cadre/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Kader Posyandu'
            },
          },
          {
            path: "/cadre/edit/:id",
            name: "cadre-edit",
            component: () => import("@/view/pages/cadre/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Kader Posyandu'
            },
          },
          {
            path: "/cadre/detail/:id",
            name: "cadre-detail",
            component: () => import("@/view/pages/cadre/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Kader Posyandu'
            },
          },
        ]
      },
      {
        path: "/staffs",
        name: "staff",
        redirect: "/staffs/list",
        component: () => import("@/view/pages/staff/Staff.vue"),
        meta: {
          title: 'Nimbang Balita | Kader Posyandu'
        },
        children: [
          {
            path: "/staffs/list",
            name: "staff-list",
            component: () => import("@/view/pages/staff/List.vue"),
            meta: {
              title: 'Nimbang Balita | Kader Posyandu'
            },
          },
          {
            path: "/staffs/add",
            name: "staff-add",
            component: () => import("@/view/pages/staff/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Kader Posyandu'
            },
          },
          {
            path: "/staffs/edit/:id",
            name: "staff-edit",
            component: () => import("@/view/pages/staff/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Kader Posyandu'
            },
          },
          {
            path: "/staffs/detail/:id",
            name: "staff-detail",
            component: () => import("@/view/pages/staff/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Kader Posyandu'
            },
          },
        ]
      },
      {
        path: "/stock-history",
        name: "stock-history",
        redirect: "/stock-history/list",
        component: () => import("@/view/pages/stock-history/StockHistory.vue"),
        meta: {
          title: 'Nimbang Balita | Riwayat Stok Bahan'
        },
        children: [
          {
            path: "/stock-history/list",
            name: "stock-history-list",
            component: () => import("@/view/pages/stock-history/List.vue"),
            meta: {
              title: 'Nimbang Balita | Riwayat Stok Bahan'
            },
          },
          {
            path: "/stock-history/add",
            name: "stock-history-add",
            component: () => import("@/view/pages/stock-history/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Riwayat Stok Bahan'
            },
          },
          {
            path: "/stock-history/edit/:id",
            name: "stock-history-edit",
            component: () => import("@/view/pages/stock-history/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Riwayat Stok Bahan'
            },
          },
          {
            path: "/stock-history/detail/:id",
            name: "stock-history-detail",
            component: () => import("@/view/pages/stock-history/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Riwayat Stok Bahan'
            },
          },
        ]
      },
      {
        path: "/material-stock",
        name: "material-stock",
        redirect: "/material-stock/list",
        component: () => import("@/view/pages/material-stock/MaterialStock.vue"),
        meta: {
          title: 'Nimbang Balita | Persediaan Bahan'
        },
        children: [
          {
            path: "/material-stock/list",
            name: "material-stock-list",
            component: () => import("@/view/pages/material-stock/List.vue"),
            meta: {
              title: 'Nimbang Balita | Persediaan Bahan'
            },
          },
          {
            path: "/material-stock/add",
            name: "material-stock-add",
            component: () => import("@/view/pages/material-stock/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Persediaan Bahan'
            },
          },
          {
            path: "/material-stock/edit/:id",
            name: "material-stock-edit",
            component: () => import("@/view/pages/material-stock/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Persediaan Bahan'
            },
          },
          {
            path: "/material-stock/detail/:id",
            name: "material-stock-detail",
            component: () => import("@/view/pages/material-stock/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Persediaan Bahan'
            },
          },
        ]
      },
      {
        path: "/message-box",
        name: "message-box",
        redirect: "/message-box/messages/:user_id/:message_to",
        component: () => import("@/view/pages/chats/Chat.vue"),
        meta: {
          title: 'Nimbang Balita | Kotak Pesan'
        },
        children: [
          // setting
          {
            path: "/message-box/messages/:user_id/:message_to",
            name: "message-box-message",
            component: () => import("@/view/pages/chats/Conversation.vue"),
            meta: {
              title: 'Nimbang Balita | Kotak Pesan',
              access_right: '5001'
            },
          },
        ]
      },
      // ::masters::
      {
        path: "/masters",
        name: "masters",
        redirect: "/masters/settings",
        component: () => import("@/view/pages/masters/Masters.vue"),
        meta: {
          title: 'Nimbang Balita | Data Pendukung - Setting'
        },
        children: [
          // setting
          {
            path: "/masters/settings",
            name: "master-setting",
            component: () => import("@/view/pages/masters/Setting.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Setting'
            },
          },

          // access-roles
          {
            path: "/masters/user-managements/role-access",
            name: "master-access-roles",
            component: () => import("@/view/pages/masters/user-managements/RoleAccess.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Hak Akses Peran',
              access_right: '9001'
            },
          },

          // access-users
          {
            path: "/masters/user-managements/user-access",
            name: "master-access-users",
            component: () => import("@/view/pages/masters/user-managements/UserAccess.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Hak Akses User',
              access_right: '9002'
            },
          },
          // admin
          {
            path: "/masters/admin",
            name: "master-admin",
            component: () => import("@/view/pages/masters/admin/List.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Admin',
              access_right: '9027'
            },
          },
          {
            path: "/masters/admin/add",
            name: "master-admin-add",
            component: () => import("@/view/pages/masters/admin/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Admin',
              access_right: '9027'
            },
          },
          {
            path: "/masters/admin/edit/:id",
            name: "master-admin-edit",
            component: () => import("@/view/pages/masters/admin/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Admin',
              access_right: '9027'
            },
          },
          {
            path: "/masters/admin/detail/:id",
            name: "master-admin-detail",
            component: () => import("@/view/pages/masters/admin/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Admin',
              access_right: '9027'
            },
          },
          // vitamin-types
          {
            path: "/masters/vitamin-types",
            name: "master-vitamin-types",
            component: () => import("@/view/pages/masters/vitamin-types/List.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Jenis Vitamin',
              access_right: '9027'
            },
          },
          {
            path: "/masters/vitamin-types/add",
            name: "master-vitamin-types-add",
            component: () => import("@/view/pages/masters/vitamin-types/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Jenis Vitamin',
              access_right: '9027'
            },
          },
          {
            path: "/masters/vitamin-types/edit/:id",
            name: "master-vitamin-types-edit",
            component: () => import("@/view/pages/masters/vitamin-types/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Jenis Vitamin',
              access_right: '9027'
            },
          },
          {
            path: "/masters/vitamin-types/detail/:id",
            name: "master-vitamin-types-detail",
            component: () => import("@/view/pages/masters/vitamin-types/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Jenis Vitamin',
              access_right: '9027'
            },
          },
          // immunization-types
          {
            path: "/masters/immunization-types",
            name: "master-immunization-types",
            component: () => import("@/view/pages/masters/immunization-types/List.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Jenis Vitamin',
              access_right: '9027'
            },
          },
          {
            path: "/masters/immunization-types/add",
            name: "master-immunization-types-add",
            component: () => import("@/view/pages/masters/immunization-types/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Jenis Vitamin',
              access_right: '9027'
            },
          },
          {
            path: "/masters/immunization-types/edit/:id",
            name: "master-immunization-types-edit",
            component: () => import("@/view/pages/masters/immunization-types/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Jenis Vitamin',
              access_right: '9027'
            },
          },
          {
            path: "/masters/immunization-types/detail/:id",
            name: "master-immunization-types-detail",
            component: () => import("@/view/pages/masters/immunization-types/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Jenis Vitamin',
              access_right: '9027'
            },
          },
          // posyandu
          {
            path: "/masters/posyandu",
            name: "master-posyandu",
            component: () => import("@/view/pages/masters/posyandu/List.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Posyandu',
              access_right: '9027'
            },
          },
          {
            path: "/masters/posyandu/add",
            name: "master-posyandu-add",
            component: () => import("@/view/pages/masters/posyandu/Add.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Posyandu',
              access_right: '9027'
            },
          },
          {
            path: "/masters/posyandu/edit/:id",
            name: "master-posyandu-edit",
            component: () => import("@/view/pages/masters/posyandu/Edit.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Posyandu',
              access_right: '9027'
            },
          },
          {
            path: "/masters/posyandu/detail/:id",
            name: "master-posyandu-detail",
            component: () => import("@/view/pages/masters/posyandu/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Posyandu',
              access_right: '9027'
            },
          },
          // blood-types
          {
            path: "/masters/blood-types",
            name: "master-blood-types",
            component: () => import("@/view/pages/masters/blood-types/List.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Golongan Darah',
              access_right: '9027'
            },
          },
          {
            path: "/masters/blood-types/detail/:id",
            name: "master-blood-types-detail",
            component: () => import("@/view/pages/masters/blood-types/Detail.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Golongan Darah',
              access_right: '9027'
            },
          },
          // roles
          {
            path: "/masters/roles",
            name: "master-roles",
            component: () => import("@/view/pages/masters/roles/Roles.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Role',
              access_right: '9027'
            },
          },
          {
            path: "/masters/roles/add",
            name: "master-roles-add",
            component: () => import("@/view/pages/masters/roles/RolesAdd.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Role',
              access_right: '9028'
            },
          },
          {
            path: "/masters/roles/edit/:id",
            name: "master-roles-edit",
            component: () => import("@/view/pages/masters/roles/RolesUpdate.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Peran',
              access_right: '9028'
            },
          },
          {
            path: "/masters/roles/detail/:id",
            name: "master-roles-detail",
            component: () => import("@/view/pages/masters/roles/RolesDetail.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Peran',
              access_right: '9027'
            },
          },
          {
            path: "/masters/roles/delete/:id",
            name: "master-roles-delete",
            component: () => import("@/view/pages/masters/roles/RolesDelete.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Peran',
              access_right: '9028'
            },
          },

          // users
          {
            path: "/masters/users",
            name: "master-users",
            component: () => import("@/view/pages/masters/users/Users.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - User',
              access_right: '9001'
            },
          },
          {
            path: "/masters/users/add",
            name: "master-users-add",
            component: () => import("@/view/pages/masters/users/UsersAdd.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - User',
              access_right: '9002'
            },
          },
          {
            path: "/masters/users/detail/:id",
            name: "master-users-detail",
            component: () => import("@/view/pages/masters/users/UsersDetail.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - User',
              access_right: '9001'
            },
          },
          {
            path: "/masters/users/edit/:id",
            name: "master-users-edit",
            component: () => import("@/view/pages/masters/users/UsersUpdate.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - User',
              access_right: '9002'
            },
          },
          // announcement-categories
          {
            path: "/masters/announcement-categories",
            name: "master-announcement-categories",
            component: () => import("@/view/pages/masters/announcement-categories/AnnouncementCategory.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Kategori Pengumuman',
              access_right: '9013'
            },
          },
          {
            path: "/masters/announcement-categories/add",
            name: "master-announcement-categories-add",
            component: () => import("@/view/pages/masters/announcement-categories/AnnouncementCategoryAdd.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Kategori Pengumuman',
              access_right: '9014'
            },
          },
          {
            path: "/masters/announcement-categories/edit/:id",
            name: "master-announcement-categories-edit",
            component: () => import("@/view/pages/masters/announcement-categories/AnnouncementCategoryUpdate.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Kategori Pengumuman',
              access_right: '9014'
            },
          },
          {
            path: "/masters/announcement-categories/detail/:id",
            name: "master-announcement-categories-detail",
            component: () => import("@/view/pages/masters/announcement-categories/AnnouncementCategoryDetail.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Kategori Pengumuman',
              access_right: '9013'
            },
          },
          // announcements
          {
            path: "/masters/announcements",
            name: "master-announcements",
            component: () => import("@/view/pages/masters/announcements/Announcement.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Pengumuman',
              access_right: '8009'
            },
          },
          {
            path: "/masters/announcements/add",
            name: "master-announcements-add",
            component: () => import("@/view/pages/masters/announcements/AnnouncementAdd.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Pengumuman',
              access_right: '8010'
            },
          },
          {
            path: "/masters/announcements/edit/:id",
            name: "master-announcements-edit",
            component: () => import("@/view/pages/masters/announcements/AnnouncementUpdate.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Pengumuman',
              access_right: '8010'
            },
          },
          {
            path: "/masters/announcements/detail/:id",
            name: "master-announcements-detail",
            component: () => import("@/view/pages/masters/announcements/AnnouncementDetail.vue"),
            meta: {
              title: 'Nimbang Balita | Data Pendukung - Pengumuman',
              access_right: '8009'
            },
          },
        ]
      },

      // blankboard
      {
        path: "/blankboard",
        name: "blankboard",
        component: () => import("@/view/pages/Blankboard.vue"),
        meta: {
          title: 'Blankboard'
        }
      },
      // profile
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/view/pages/custom_pages/Profile.vue"),
        meta: {
          title: 'Nimbang Balita | Profil'
        }
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/view/pages/auth/Login"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/view/pages/auth/Login"),
        meta: {
          title: 'Nimbang Balita | Login'
        }
      },
      {
        name: "register",
        path: "/register",
        component: () => import("@/view/pages/auth/Login"),
        meta: {
          title: 'Nimbang Balita | Register'
        }
      }
    ]
  },
  // redirect to 404
  {
    path: "*",
    redirect: "/404"
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: {
      title: 'Nimbang Balita | Page Not Found'
    },
    component: () => import("@/view/pages/error/Error-1.vue")
  },

  // 503
  {
    path: '/access-forbidden',
    name: 'access-forbidden',
    meta: {
      title: 'Nimbang Balita | Access Forbidden'
    },
    component: () => import('@/view/pages/error/Error-7.vue')
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Route Middleware
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.access_right)) {
//     let localStorageAccessRight = JSON.parse(localStorage.getItem('access_right_display'))
//     if (localStorageAccessRight != null) {
//       let result = false
//       for (let i = 0; i < localStorageAccessRight.length; i++) {
//         result = false
//         if (localStorageAccessRight[i] == to.meta.access_right) {
//           result = true
//           break
//         }
//       }
//       if (result) {
//         next()
//       } else {
//         next('/access-forbidden')
//       }
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

export default router;
