import ApiService from "../api.service";
import JwtService from "@/core/services/jwt.service";
import qs from "querystring";
import store from '@/core/services/store'
import { UPDATE_PERSONAL_INFO } from '@/core/services/store/profile.module'

import Swal from 'sweetalert2'
import router from '@/router.js'

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const REFRESH_TOKEN = "refreshToken";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_USER = "setUserData";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    var login_data = qs.stringify({
      username: credentials.email,
      password: credentials.password,
      grant_type: "password",
      client_id: "2",
      // Host
      client_secret: "6IlZGOI2oXiETcSft0n17KS2hrafzyiQPA1eNN0L",
      // Local
      // client_secret: "D4QSTV9PUcV0MCAm8d8w9Z3n7r2GdWK3L8Yc6vAX",
      scope: "*",
    });
    return new Promise((resolve) => {
      ApiService.post("oauth/token", login_data)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          ApiService.setHeader();
          context.commit(SET_USER);
          // //("Data Return From Login", data);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(SET_ERROR, data);
          Swal.fire({
            title: '',
            text: 'Email atau password tidak valid',
            icon: 'error',
          })
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      // //("creden", credentials)
      ApiService.post("api/users", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    // //('before verify');
    if (JwtService.getToken()) {
      // //('gettoken true');
      ApiService.setHeader();
      ApiService.get("api/roles")
        .then(({ data }) => {
          // //("token active", data)
          if (data == "Unauthorized.") {
            // //('Refresh Token')
            var refresh_data_token = {
              grant_type: "refresh_token",
              refresh_token: JwtService.getRefreshToken(),
              client_id: "2",
              // Host
              client_secret: "6IlZGOI2oXiETcSft0n17KS2hrafzyiQPA1eNN0L",
              // Local
              // client_secret: "D4QSTV9PUcV0MCAm8d8w9Z3n7r2GdWK3L8Yc6vAX",
              scope: "*",
            };
            return new Promise((resolve) => {
              ApiService.post("oauth/token", refresh_data_token)
                .then(({ data }) => {
                  context.commit(SET_AUTH, data);
                  ApiService.setHeader();
                  context.commit(SET_USER);
                  // //("Data Return From Refresh Token", data);
                  resolve(data);
                })
                .catch(({ data }) => {
                  context.commit(SET_ERROR, data);
                  context.commit(PURGE_AUTH);
                  router.push({ path: "/login" })
                  // //("Refresh Token False", data);
                });
            });
          }
          // context.commit(SET_AUTH, data);
        })
        .catch(({ error }) => {
          if (error.response.status == 401) {
            //("responseerrorr", error.response)
            //("token expired")
            //('Refresh Token')
            var refresh_data_token = {
              grant_type: "refresh_token",
              refresh_token: JwtService.getRefreshToken(),
              client_id: "2",
              // Host
              client_secret: "6IlZGOI2oXiETcSft0n17KS2hrafzyiQPA1eNN0L",
              // Local
              // client_secret: "D4QSTV9PUcV0MCAm8d8w9Z3n7r2GdWK3L8Yc6vAX",
              scope: "*",
            };
            return new Promise((resolve) => {
              ApiService.post("oauth/token", refresh_data_token)
                .then(({ data }) => {
                  context.commit(SET_AUTH, data);
                  ApiService.setHeader();
                  context.commit(SET_USER);
                  //("Data Return From Refresh Token", data);
                  resolve(data);
                })
                .catch(({ data }) => {
                  context.commit(SET_ERROR, data);
                  context.commit(PURGE_AUTH);
                  router.push({ path: "/login" })
                  //("Refresh Token False", data);
                });
            });
          }
          // context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      //('get token false');
      // router.push({ path: "/login" })
      // context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [REFRESH_TOKEN](context) {
    //('Refresh Token')
    var refresh_data_token = {
      grant_type: "refresh_token",
      refresh_token: state.user.refresh_token,
      client_id: "2",
      // Host
      client_secret: "6IlZGOI2oXiETcSft0n17KS2hrafzyiQPA1eNN0L",
      // Local
      // client_secret: "D4QSTV9PUcV0MCAm8d8w9Z3n7r2GdWK3L8Yc6vAX",
      scope: "*",
    };
    return new Promise((resolve) => {
      ApiService.post("oauth/token", refresh_data_token)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          ApiService.setHeader();
          context.commit(SET_USER);
          //("Data Return From Refresh Token", data);
          resolve(data);
        })
        .catch(({ data }) => {
          context.commit(SET_ERROR, data);
          context.commit(PURGE_AUTH);
          router.push({ path: "/login" })
          //("Refresh Token False", data);
        });
    });
  }

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
    //("state error", state)
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    // JwtService.saveToken(state.user.token);
    JwtService.saveToken(state.user.access_token);
    JwtService.saveRefreshToken(state.user.refresh_token);
  },
  [SET_USER](state) {
    ApiService.get('api/users/me').then((data) => {
      // window.localStorage.setItem("user", JSON.stringify(data.data));

      JwtService.setUser(JSON.stringify(data.data.data))
      store.dispatch(UPDATE_PERSONAL_INFO, data.data.data)
    })
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
